export const TIME_FORMAT = 'HH:mm:ss';

export const HALF_LENGTH = 2700;

export const SECOND_LENGTH = 1000;

export const FORMAT_DDMMYYYY = 'DD.MM.YYYY';
export const FORMAT_DDDMMYY_HHMMSS = 'DD.MM.YYYY HH:mm';
export const FORMAT_MMSS = 'mm:ss';

export const MINUTE_LENGTH = 60;
export const HOUR_LENGTH = 3600;
