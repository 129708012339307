import {Flex, Text} from '@chakra-ui/react';
import styled from '@emotion/styled';

import appTheme from '@/components/ui-kit/theme/app-theme';

export const CardWrapper = styled(Flex)<{
    isTechEvent?: boolean;
    isPlaying?: boolean;
}>`
    background-color: ${(props) =>
                            props.isTechEvent
                                ? appTheme.colors.turquoise
                                : appTheme.colors.white};
    border-radius: 12px;
    align-items: center;
    justify-content: ${(props) => 
                        `${props.isTechEvent ? null : 'space-between'}`};
    cursor: pointer;
    border: ${(props) =>
                `2px solid ${
                    props.isPlaying
                        ? appTheme.colors.turquoise
                        : props.isTechEvent
                        ? appTheme.colors.turquoise
                        : appTheme.colors.white
                }  `};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    min-width: 290px;
    max-height: 60px;
    padding: ${(props) =>
            props.isTechEvent
                    ? '9px 0 9px 39px'
                    : '8px 8px 9px 8px'};

    @media (min-width: 512px) and (max-width: 1199px) {
        min-width: 290px;
        max-height: 60px;
        padding: 10px 8px 11px 8px;
    }

    @media (min-width: 480px) and (max-width: 511px) {
        min-width: 290px;
        max-height: 60px;
        padding: 10px 7px 11px 8px;
    }

    @media (min-width: 360px) and (max-width: 479px) {
        min-width: 290px;
        max-height: 57px;
        padding: 7px 8px 10px 7px;
    }
`;

export const Card= styled(Flex)`
    align-items: center;
`;

export const TimeWrapper = styled(Text)<{
    isTechEvent?: boolean;
}>`
    font-size: 12px;
    font-weight: 700;
    white-space: nowrap;
    position: relative;
    background-color: ${appTheme.colors.turquoise};
    color: ${appTheme.colors.white};
    border-radius: 2px;
    padding: 1px 3px;
    width: fit-content;
    line-height: 14px;
    margin-left: 7px;
    margin-right: ${(props) => props.isTechEvent ? '10px' : '0'};

    @media (min-width: 512px) and (max-width: 1199px) {
        margin-left: 7px;
        font-size: 11px;
        line-height: 14px;
        padding: 1px 4px;
    }
    
    @media (min-width: 480px) and (max-width: 511px) {
        margin-left: 6px;
        font-size: 11px;
        line-height: 14px;
        padding: 1px 4px;
    }

    @media (min-width: 360px) and (max-width: 479px) {
        margin-top: 1px;
        margin-left: 7px;
        font-size: 12px;
        line-height: 14px;
        padding: 0 3px 1px;
    }
`;

export const Time = styled.span`
    position: absolute;
    top: -12px;
    right: 0;
    font-size: 10px;
    color: ${appTheme.colors.blue[700]};

    @media (min-width: 360px) and (max-width: 479px) {
        top: -10px;
        font-size: 7px;
    }
`;

export const getEvent = (isTechEvent?: boolean) => styled(Text)`
    color: ${isTechEvent
        ? appTheme.colors.white
        : appTheme.colors.gray[500]};
    font-size: ${isTechEvent ? '14px' : '12px'};
    max-width: ${isTechEvent ? 'none' : '80px'};
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 1px;
    margin-left: 7px;

    @media (min-width: 412px) and (max-width: 1199px) {
        font-size: 11px;
        margin-top: 2.5px;
        margin-left: 7px;
    }

    @media (min-width: 480px) and (max-width: 511px) {
        font-size: 11px;
        margin-top: 1.5px;
        margin-left: 6px;
    }

    @media (min-width: 360px) and (max-width: 479px) {
        font-size: 11px;
        margin-top: 2px;
        margin-left: 8px;
    }
`;

export const PlayerWrapper = styled(Flex)`
    align-items: center;
    gap: 4px;
`;

export const Player = styled(Text)`
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${appTheme.colors.blue[700]};
    margin-top: 2px;

    @media (min-width: 512px) and (max-width: 1199px) {
        margin-top: 0;
    }

    @media (min-width: 480px) and (max-width: 511px) {
        margin-top: 1px;
    }

    @media (min-width: 360px) and (max-width: 479px) {
        font-size: 14px;
        margin-top: 2px;
    }
`;
