import {EntityAPI} from '../utils/entity-api';

import {IMatchTournament} from '@/lib/types/dto/match';
import {matchMomentsApi} from '@/lib/utils/http/api';

class MatchApi implements EntityAPI {
  public find = async (id: number) =>
      matchMomentsApi.get<IMatchTournament>(`/${id}`);
}

export const apiInstance = new MatchApi();
