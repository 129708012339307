import {Empty, Select} from 'antd';
import {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {MAX_SELECT_TAGS_COUNT, PLACEHOLDER, PLAYER_POSITIONS} from './consts';
import {Wrapper, StyledSelect, optionClassName, LoaderWrapper} from './styles';
import {Component} from './types';

import Loader from '@/components/loader';
import {LANGUAGE_STORAGE_KEY} from '@/components/providers/i18next/consts';
import {usePlayers} from '@/lib/queries/use-player-moments';
import {IPlayer} from '@/lib/types/dto/moment';

const {Option} = Select;

const PlayersSelector: Component = ({
    matchId,
    teamId,
    momentsFilters,
    setPlayers,
    momentsPlayers,
}) => {
    const {t} = useTranslation();
    const language = localStorage.getItem(LANGUAGE_STORAGE_KEY);

    const {data: players, isLoading} =
        usePlayers({matchId, playerId: momentsPlayers, teamId, filter: momentsFilters});

    const getPlayerSelectOptions = () => {
        if (players) {
            const sortedTeamPlayers = language ? players.map(player => {
                const positionsLanguage =
                    'position' + language.charAt(0).toUpperCase() + language.slice(1) as keyof IPlayer;
                if (PLAYER_POSITIONS[language].includes((player as any)[positionsLanguage])) {
                    return player;
                }
            }).filter(player => player) : players;
            return [...sortedTeamPlayers]
                .map(player => ({
                    value: player?.playerId,
                    label: player?.name,
                }));
        }
        return [];
    };

    const selectOptions = useMemo(() => {
        return getPlayerSelectOptions().map((item) => {
            return (
                <Option
                    key={item.value}
                    value={item.value}
                    className={optionClassName}
                >
                    {item.label}
                </Option>
            );
        });
    }, [players, t]);

    const empty = useMemo(() => {
        return isLoading ? (
            <LoaderWrapper>
                <Loader />
            </LoaderWrapper>
        ) : (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('ui.search.emptySearch')}
            />
        );
    }, [isLoading, t]);

    const onChange = (options: string[]) => {
        const playerIds = options.map(option => +option);
        setPlayers(playerIds);
    };

    return (
        <Wrapper>
            <StyledSelect
                loading={isLoading}
                notFoundContent={empty}
                mode="multiple"
                optionFilterProp="children"
                placeholder={t(PLACEHOLDER)}
                maxTagCount={MAX_SELECT_TAGS_COUNT}
                onChange={onChange as any}
            >
                {selectOptions}
            </StyledSelect>
        </Wrapper>
    );
};

export default memo(PlayersSelector);
