import {LoaderWrapper, LoaderSpinner} from './styles';
import {Component} from './types';

const Loader: Component = () => {
  return (
    <LoaderWrapper>
      <LoaderSpinner />
    </LoaderWrapper>
  );
};

export default Loader;
