import {useQuery} from 'react-query';

import {videoQueryKey} from './consts';

import {apiInstance as videoApi} from '@/lib/api/video';
import {IVideo, IVideoDto} from '@/lib/types/video';

const mapVideoDtoToEntity = (dto: IVideoDto): IVideo => ({
  id: dto.id,
  isActive: dto.is_active,
  isDeleted: dto.is_deleted,
  sportsCategory: dto.sports_category,
  matchEndTimestamp: dto.match_end_timestamp,
  matchStartTimestamp: dto.match_start_timestamp,
  gameRestartTimestamp: dto.game_restart_timestamp,
  tagList: dto.tag_list,
  labelList: dto.label_list,
  updatedAt: dto.updated_at,
  date: dto.date,
  desctiprion: dto.description,
  url: dto.url,
  preview: dto.preview,
  header: dto.header,
  createdAt: dto.createdAt,
  createdBy: dto.created_by,
});

export function useVideo(id?: string) {
  return useQuery<IVideo | undefined>(videoQueryKey, async () => {
    if (!id) {
      return;
    }
    const response = await videoApi.find(id);

    if (response.data) {
      return mapVideoDtoToEntity(response.data);
    }
  });
}
