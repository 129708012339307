import {Flex} from '@chakra-ui/react';
import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const ContentWrapper = styled(Flex)`
    max-width: 1920px;
    margin: 0 auto;
    padding: 50px;

    @media (min-width: 1200px) and (max-width: 1250px) {
        padding: 50px 30px;
    }

    @media (min-width: 360px) and (max-width: 1199px) {
        flex-direction: column;
        padding: 50px 0;
    }
`;

export const tabsClassName = css`
    margin-top: 66px;
    min-width: 298px;

    .ant-tabs-nav-wrap {
        justify-content: center;
    }

    @media (min-width: 360px) and (max-width: 1199px) {
        margin-top: 0;
    }
`;
