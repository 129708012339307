import {ReactPlayerProps} from 'react-player';

export const defaultReactPlayerConfig: ReactPlayerProps['config'] = {
    file: {
        attributes: {
            onKeyDownCapture: (evt: KeyboardEvent) => {
                evt.preventDefault();
            },
        },
    },
};

export const SHIFT_SECONDS = 10;

/** Возможное отклонение в секундах для расчета статуса трансляции */
export const SECONDS_DEVIATION = 10;

export const FRACTION_COUNT = 1;
