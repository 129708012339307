import {useQuery} from 'react-query';

import {broadcastQueryKey} from '../constants/query-keys';

import {apiInstance as broadcastApi} from '@/lib/api/broadcast';
import {IBroadcast, IBroadcastDto} from '@/lib/types/broadcast';

const mapBroadcastDtoToEntity = (dto: IBroadcastDto): IBroadcast => {
  return {
    id: dto.id,
    isActive: dto.is_active,
    isDeleted: dto.is_deleted,
    preview: dto.preview,
    header: dto.header,
    url: dto.url,
    clientUrl: dto.client_url,
    createdBy: dto.created_by,
    sportsCategory: dto.sports_category,
    endTime: dto.end_time,
    startTime: dto.start_time,
    desctiprion: dto.description,
    tagList: dto.tag_list,
    labelList: dto.label_list,
    records: dto.records,
  };
};

export function useBroadcast(broadcastId?: number) {
  return useQuery(broadcastQueryKey, async () => {
    if (!broadcastId) {
      return;
    }
    const response = await broadcastApi.find(broadcastId);

    if (response.data) {
      return mapBroadcastDtoToEntity(response.data);
    }
  });
}
