import {Box} from '@chakra-ui/react';
import {Empty, Select} from 'antd';
import {memo, useMemo} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {MOMENTS_TRANSLATION_KEY} from '../../consts';

import {
    PLACEHOLDER_TRANSLATION_KEY,
    MAX_SELECT_TAGS_COUNT,
    TEAM_MOMENTS,
} from './consts';
import {
    StyledSelect,
    optionClassName,
    LoaderWrapper,
} from './styles';
import {Component} from './types';
import {selectFilterFunc} from './utils';

import Loader from '@/components/loader';
import {useMomentsType} from '@/lib/queries/use-moments';

const {Option} = Select;

const MomentsSelector: Component = ({
    matchId,
    name,
    momentsPlayers,
}) => {
    const {t} = useTranslation();
    const {control} = useFormContext();

    const {data: momentTypes, isLoading} =
        useMomentsType({matchId, playerId: momentsPlayers});

    const getMomentSelectOptions = () => {
        return (momentTypes || TEAM_MOMENTS).map((type: string) => ({
            value: type,
            label: type,
        }));
    };

    const selectOptions = useMemo(() => {
        return getMomentSelectOptions().map((item) => {
            return (
                <Option
                    key={item.value}
                    value={item.value}
                    className={optionClassName}
                >
                    {t(`${MOMENTS_TRANSLATION_KEY}.${item.label}`)}
                </Option>
            );
        });
    }, [momentTypes, t]);

    const empty = useMemo(() => {
        return isLoading ? (
            <LoaderWrapper>
                <Loader />
            </LoaderWrapper>
        ) : (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('ui.search.emptySearch')}
            />
        );
    }, [isLoading, t]);

    return (
        <Box>
            <Controller
                name={name}
                control={control}
                render={({field}) => (
                    <StyledSelect
                        {...field}
                        loading={isLoading}
                        notFoundContent={empty}
                        mode="multiple"
                        optionFilterProp="children"
                        placeholder={t(PLACEHOLDER_TRANSLATION_KEY)}
                        maxTagCount={MAX_SELECT_TAGS_COUNT}
                        filterOption={selectFilterFunc}
                    >
                        {selectOptions}
                    </StyledSelect>
                )}
            />
        </Box>
    );
};

export default memo(MomentsSelector);
