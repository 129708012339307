import {Flex, Text, Image} from '@chakra-ui/react';
import styled from '@emotion/styled';

import appTheme from '@/components/ui-kit/theme/app-theme';

export const Wrapper = styled(Flex)`
    flex-direction: column;
    margin-bottom: 5px;

    @media (min-width: 512px) and (max-width: 1199px) {
        margin-bottom: 20px;
        padding: 0 15px;
    }

    @media (min-width: 480px) and (max-width: 511px) {
        margin-bottom: 20px;
        padding: 0 14px;
    }

    @media (min-width: 360px) and (max-width: 479px) {
        margin-bottom: 26px;
        padding: 0 14px;
    }
`;

export const TitlesRow = styled(Flex)`
    width: 100%;
    margin: 0 auto;

    @media (min-width: 512px) and (max-width: 1199px) {
        max-width: 480px;
    }
    
    @media (min-width: 480px) and (max-width: 511px) {
        justify-content: space-between;
        padding-left: 3px;
        max-width: 450px;
    }

    @media (min-width: 360px) and (max-width: 479px) {
        justify-content: space-between;
        max-width: 330px;
    }
`;

export const SelectorsRow = styled(Flex)`
    max-width: 955px;
    margin-bottom: 14px;
    justify-content: space-between;

    @media (min-width: 512px) and (max-width: 1199px) {
        max-width: 482px;
        width: 100%;
        margin: 0 auto;
    }

    @media (min-width: 480px) and (max-width: 511px) {
        max-width: 451px;
        width: 100%;
        margin: 0 auto;
    }

    @media (min-width: 360px) and (max-width: 479px) {
        max-width: 332px;
        width: 100%;
        margin: 0 auto;
    }
`;

export const RowItem = styled(Flex)<{
    isSelected?: boolean;
}>`
    display: block;
    flex-direction: column;
    
    &:last-of-type {
        margin-right: 0;
    }

    @media (min-width: 512px) and (max-width: 1199px) {
        margin-right: 0;
    }

    @media (min-width: 480px) and (max-width: 511px) {
        margin-right: 0;
    }

    @media (min-width: 360px) and (max-width: 511px) {
        margin-right: 0;
        display: ${(props) =>
                props.isSelected
                        ? 'block'
                        : 'none'};
    }
`;

export const TitleWrapper = styled(Flex)`
    align-items: center;
    margin-bottom: 10px;

    @media (min-width: 512px) and (max-width: 1199px) {
        margin-bottom: 14px;
    }

    @media (min-width: 480px) and (max-width: 511px) {
        margin-bottom: 14px;
    }

    @media (min-width: 360px) and (max-width: 479px) {
        margin-bottom: 14px;
    }
`;

export const Title = styled(Text)<{
    isSelected?: boolean;
}>`
    font-size: 23px;
    font-weight: 600;
    line-height: 1.4;
    color: ${appTheme.colors.blue[700]};
    cursor: auto;
    pointer-events: none;
    margin-left: 8px;

    @media (min-width: 512px) and (max-width: 1199px) {
        font-size: 16px;
        cursor: auto;
        pointer-events: none;
        margin-left: 8px;
    }

    @media (min-width: 480px) and (max-width: 511px) {
        font-size: 16px;
        color: ${(props) =>
                    props.isSelected
                        ? appTheme.colors.turquoise
                        : appTheme.colors.blue[700]};
        cursor: pointer;
        pointer-events: auto;
    }

    @media (min-width: 360px) and (max-width: 479px) {
        font-size: 13px;
        color: ${(props) =>
                    props.isSelected
                        ? appTheme.colors.turquoise
                        : appTheme.colors.blue[700]};
        cursor: pointer;
        pointer-events: auto;
    }
`;

export const Name = styled(Text)<{
    isSelected?: boolean;
}>`
    font-size: 23px;
    font-weight: 600;
    line-height: 1.4;
    color: ${appTheme.colors.blue[700]};
    cursor: auto;
    pointer-events: none;
    margin-left: 13px;
    width: 296px;

    @media (min-width: 512px) and (max-width: 1199px) {
        font-size: 16px;
        cursor: auto;
        pointer-events: none;
        margin-left: 8px;
        width: 129px;
    }

    @media (min-width: 480px) and (max-width: 511px) {
        font-size: 16px;
        color: ${(props) =>
                props.isSelected
                        ? appTheme.colors.turquoise
                        : appTheme.colors.blue[700]};
        cursor: pointer;
        pointer-events: auto;
        margin-left: 8px;
        max-width: 115px;
        width: max-content;
    }

    @media (min-width: 360px) and (max-width: 479px) {
        font-size: 13px;
        color: ${(props) =>
                props.isSelected
                        ? appTheme.colors.turquoise
                        : appTheme.colors.blue[700]};
        cursor: pointer;
        pointer-events: auto;
        margin-left: 8px;
        max-width: 80px;
        width: max-content;
    }
`;

export const Logo = styled(Image)`
    height: 24px;
    margin-left: 5px;
    
    @media (min-width: 512px) and (max-width: 1199px) {
        margin-left: 3px;
    }

    @media (min-width: 480px) and (max-width: 511px) {
        margin-left: 0;
    }
    
    @media (min-width: 360px) and (max-width: 479px) {
        margin-left: 3px;
    }
`;
