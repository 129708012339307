import TranslatedLabel from '@/components/translated-label';

export const makeVideoTabsItems = (broadcastsLength?: number) => {
    return Array(broadcastsLength)
        .fill({})
        .map((_, idx) => {
            const videoCount = idx + 1;
            return {
                key: idx.toString(),
                tabKey: `video${idx}`,
                label:
                    idx === 0 ? (
                        <TranslatedLabel
                            translationKey="ui.video"
                            suffix={` ${videoCount}`}
                        />
                    ) : (
                            videoCount
                        ),
                    };
        });
};
