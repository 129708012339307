import {Text, Tooltip} from '@chakra-ui/react';
import {memo} from 'react';

import {Component} from './types';

const EllipsisText: Component = ({text, maxLength, as}) => {
    if (text.length <= maxLength) {
        return <Text as={as}>{text}</Text>;
    }

    return (
        <Tooltip label={text}>
            <Text as={as}>{`${text.slice(0, maxLength)}...`}</Text>
        </Tooltip>
    );
};

export default memo(EllipsisText);
