import {memo} from 'react';

import videoNotFound from '../../assets/video-not-found.png';

import {ImageWrapper, NotFoundImage} from './styles';
import {Component} from './types';

const NotFound: Component = () => {
    return (
        <ImageWrapper>
            <NotFoundImage src={videoNotFound} />
        </ImageWrapper>
    );
};

export default memo(NotFound);
