import {Undefinable} from '@/lib/types';

export const createQueryString = (
    params?: Record<string, Undefinable<string | number | (string | number)[] | boolean>>,
): string => {
    if (!params) {
        return '';
    }

    return Object.keys(params).reduce((acc: string, cur: string) => {
        if (typeof params[cur] === undefined) {
            return acc;
        }

        let queryParam = !acc ? '?' : '&';

        if (Array.isArray(params[cur])) {
            queryParam += `${cur}=${(params[cur] as Array<string | number>).join(',')}`;
        } else {
            queryParam += `${cur}=${params[cur]}`;
        }

        acc += queryParam;

        return acc;
    }, '');
};
