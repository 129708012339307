import {makeApiHost} from './utils';

import {AppConfig} from '@/lib/types/app-config';

export const config: AppConfig = {
    app: {
        port: process.env.APP_PORT || 3001,
    },
    env: {
        nodeEnv: process.env.NODE_ENV,
    },

    api: {
        matchMoments: makeApiHost('analytics/public/private_match_moments'),
    },
    ux: {
        inputDebounceDelay: 300,
    },
};
