import {Box, Flex} from '@chakra-ui/react';
import styled from '@emotion/styled';

export const Wrapper = styled(Flex)`
    flex-direction: column;

    @media (min-width: 512px) and (max-width: 1199px) {
        padding: 0 11px;
    }

    @media (min-width: 480px) and (max-width: 511px) {
        padding: 0 11px;
    }

    @media (min-width: 360px) and (max-width: 479px) {
        padding: 0 11px;
    }
`;

export const FrameWrapper = styled(Box)`
    background-color: white;
    height: 184px;
    border-radius: 16px;

    &:first-of-type {
        height: max-content;
    }
`;
