import axios from 'axios';

import {LANGUAGE_STORAGE_KEY} from '@/components/providers/i18next/consts';
import {config} from '@/lib/configs';

export const matchMomentsApi = axios.create({
    baseURL: config.api.matchMoments,
});

const localeApis = [
    matchMomentsApi,
];

localeApis.forEach((_item) => {
    _item.interceptors.request.use((config) => ({
        ...config,
        headers: {
            ...config.headers,
            'X-Locale': `${localStorage.getItem(LANGUAGE_STORAGE_KEY)}`,
        },
    }) as any);
});
