import {BroadcastMoment} from './types';

export const MAX_LENGTH_PLAYER_NAME = 16;

const BASE_MOMENT_LENGTH = 15;

const DEFAULT_MOMENT_LENGTH = 12;

const DEFAULT_ATTACK_MOMENT_LENGTH = 30;

export const MOMENTS_LENGTH: Record<string, {start: number; length: number}> = {
    [BroadcastMoment.Goals]: {
        start: -10,
        length: BASE_MOMENT_LENGTH,
    },
    [BroadcastMoment.GoalsAuto]: {
        start: -10,
        length: BASE_MOMENT_LENGTH,
    },
    [BroadcastMoment.Goalmoments]: {
        start: -10,
        length: BASE_MOMENT_LENGTH,
    },
    [BroadcastMoment.ShotsOnTarget]: {
        start: -10,
        length: BASE_MOMENT_LENGTH,
    },
    [BroadcastMoment.CrossesSuccess]: {
        start: -5,
        length: BASE_MOMENT_LENGTH,
    },
    [BroadcastMoment.Freekicks]: {
        start: -5,
        length: BASE_MOMENT_LENGTH,
    },
    [BroadcastMoment.Corners]: {
        start: -5,
        length: BASE_MOMENT_LENGTH,
    },
    [BroadcastMoment.Outs]: {
        start: -5,
        length: BASE_MOMENT_LENGTH,
    },
    [BroadcastMoment.Mistakes]: {
        start: -10,
        length: BASE_MOMENT_LENGTH,
    },
    [BroadcastMoment.CardsYellow]: {
        start: -10,
        length: BASE_MOMENT_LENGTH,
    },
    [BroadcastMoment.CardsRed]: {
        start: -10,
        length: BASE_MOMENT_LENGTH,
    },
    [BroadcastMoment.Fouls]: {
        start: -5,
        length: BASE_MOMENT_LENGTH,
    },
    [BroadcastMoment.PassesToPenaltyZone]: {
        start: -5,
        length: BASE_MOMENT_LENGTH,
    },
    [BroadcastMoment.CarryingToPenaltyZone]: {
        start: -5,
        length: BASE_MOMENT_LENGTH,
    },
    [BroadcastMoment.FoulsWithPenalty]: {
        start: -5,
        length: BASE_MOMENT_LENGTH,
    },
    [BroadcastMoment.Counterattacks]: {
        start: 0,
        length: DEFAULT_ATTACK_MOMENT_LENGTH,
    },
    [BroadcastMoment.AttacksPositionalWithShot]: {
        start: 0,
        length: DEFAULT_ATTACK_MOMENT_LENGTH,
    },
    [BroadcastMoment.FirstHalfStart]: {
        start: 0,
        length: DEFAULT_MOMENT_LENGTH,
    },
    [BroadcastMoment.SecondHalfStart]: {
        start: 0,
        length: DEFAULT_MOMENT_LENGTH,
    },
    [BroadcastMoment.GoalkeeperKicks]: {
        start: -5,
        length: DEFAULT_MOMENT_LENGTH,
    },
    [BroadcastMoment.Dribbles]: {
        start: -5,
        length: DEFAULT_MOMENT_LENGTH,
    },
    [BroadcastMoment.Shots]: {
        start: -5,
        length: DEFAULT_MOMENT_LENGTH,
    },
};

export const MOMENTS_TRANSLATION_KEY = 'moments';
