import {Box} from '@chakra-ui/react';
import styled from '@emotion/styled';

export const PlayerWrapper = styled(Box)`
    padding-right: 6px;
    
    @media (min-width: 512px) and (max-width: 1199px) {
        padding: 0 15px;
    }
    
    @media (min-width: 480px) and (max-width: 511px) {
        padding: 0 15px;
    }
    
    @media (min-width: 360px) and (max-width: 479px) {
        padding: 0 15px;
    }
`;
