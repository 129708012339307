import {isDefined} from '@/lib/utils/type-guards';

import {MOMENTS_LENGTH} from './consts';
const BASE_SHIFT_SECONDS = -15;

export const prepareTime = (time: number, action: string) => {
    const shiftSeconds = isDefined(MOMENTS_LENGTH[action]?.start)
        ? MOMENTS_LENGTH[action].start
        : BASE_SHIFT_SECONDS;

    return time + shiftSeconds;
};

export const getMomentNameMaxLength = (isTechMoment: boolean) => {
    return isTechMoment ? 16 : 10;
};
