import {BaseOptionType, SelectProps} from 'antd/es/select';

export const selectFilterFunc: SelectProps<
    unknown,
    BaseOptionType
>['filterOption'] = (input, option) => {
    return ((option?.children as unknown as string) ?? '')
        .toLowerCase()
        .includes(input.toLowerCase());
};
