import styled from '@emotion/styled';
import {Typography} from 'antd';

export const Text = styled(Typography.Text)`
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    line-height: inherit;
    text-align: inherit;
`;
