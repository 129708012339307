import {COLORS as TRUE_COLORS} from './colors';

export const baseTheme = {
    colors: {
        white: TRUE_COLORS.white,
        turquoise: TRUE_COLORS.turquoise,
        gray: {
            300: TRUE_COLORS.lightGray,
            700: TRUE_COLORS.darkGray,
        },
        blue: {
            700: TRUE_COLORS.darkBlue,
        },
    },
};
