import {Box, BoxProps, ImageProps} from '@chakra-ui/react';
import {Theme} from '@emotion/react';
import styled, {StyledComponent} from '@emotion/styled';
import {DetailedHTMLProps, HTMLAttributes, ImgHTMLAttributes} from 'react';

export const ThumbnailWrapper: StyledComponent<
    HTMLAttributes<HTMLDivElement>
> = styled.div`
    width: 100%;
    height: 100%;
`;

export const ThumbnailImage: StyledComponent<
    {
        theme?: Theme | undefined;
        as?: ImageProps['as'];
    },
    DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: black;
`;

export const PlayerWrapper: StyledComponent<BoxProps> = styled(Box)`
    aspect-ratio: 16/9;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: black;
`;
