import {Box, Image} from '@chakra-ui/react';
import styled from '@emotion/styled';

import {COLORS} from '@/lib/styles/colors';

export const ImageWrapper = styled(Box)`
  overflow: hidden;
  background-color: ${COLORS.BLACK};
  height: 100%;
  width: 100%;
`;

export const NotFoundImage = styled(Image)`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;
