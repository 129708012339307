import {toNumber} from 'lodash';

import {Undefinable} from '../types';

/** Утилита для парсинга числа с проверкой на NaN
 * на основе toNumber lodash
 */
export const parseNumber = (value?: string | string[]): Undefinable<number> => {
    const parsedValue = toNumber(value);
    return !Number.isNaN(parsedValue) ? parsedValue : undefined;
};
