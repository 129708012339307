import {Flex, Box} from '@chakra-ui/react';
import {css} from '@emotion/css';
import styled from '@emotion/styled';
import {Select} from 'antd';

import appTheme from '@/components/ui-kit/theme/app-theme';

export const Wrapper = styled(Box)`
    margin-top: 6px;
`;

export const StyledSelect = styled(Select)`
    border: none;
    color: ${appTheme.colors.gray[300]};
    cursor: pointer;
    width: 280px;

    @media (min-width: 512px) and (max-width: 1199px) {
        width: 154px;
        height: 32px;
    }

    @media (min-width: 480px) and (max-width: 511px) {
        width: 451px;
        height: 32px;
    }

    @media (min-width: 360px) and (max-width: 479px) {
        width: 331px;
        height: 32px;
    }
`;

/** Без important не применяется никак */
export const optionClassName = css`
    color: ${appTheme.colors.gray[500]} !important;
`;

export const LoaderWrapper = styled(Flex)`
    justify-content: center;
    margin: 10px 0;
`;
