import {
    ForwardRefExoticComponent,
    PropsWithChildren,
    RefAttributes,
} from 'react';
import ReactPlayer, {ReactPlayerProps} from 'react-player';

import {
    PlayerEntityStates,
    PlayerEntityTypes,
} from '../../constants/player-entity-type';

export enum ELabel {
    Id = 'id',
    Name = 'name',
    ShortName = 'short_name',
    Color = 'color',
    Icon = 'icon',
    Description = 'description',
}

export interface ILabel {
    [ELabel.Id]: number;
    [ELabel.Name]: string;
    [ELabel.ShortName]: string;
    [ELabel.Color]: string;
    [ELabel.Icon]: string;
    [ELabel.Description]?: string;
}

export enum ETimeLabel {
    Id = 'id',
    LabelTimestamp = 'label_timestamp',
    BroadcastId = 'broadcast_id',
    VideoId = 'video_id',
    LabelId = 'label_id',
    Label = 'label',
}
export interface ITimeLabel {
    [ETimeLabel.Id]: number;
    [ETimeLabel.LabelTimestamp]: string;
    [ETimeLabel.BroadcastId]?: number;
    [ETimeLabel.VideoId]?: number;
    [ETimeLabel.LabelId]?: number;
    [ETimeLabel.Label]: ILabel;
}

export type PlayerProps = ReactPlayerProps & {
    url?: string;
    preview?: string;
    /** @param entityState - состояние видео/трансляции */
    entityState: PlayerEntityStates;
    type: PlayerEntityTypes;
    id?: string;
    /** Признак для переиспользуемого компонента плеера:
     * true - компонент вставляется в iframe и используется как embed,
     * false - компонент используется напрямую на медиаплатформе
     */
    isEmbed?: boolean;
};

export type Props = PropsWithChildren<PlayerProps>;

export type Component = ForwardRefExoticComponent<
    Omit<Props, 'ref'> & RefAttributes<ReactPlayer>
>;

export enum ArrowButtonsKeys {
    Right = 'ArrowRight',
    Left = 'ArrowLeft',
}
