import {
    Box,
    Button,
    ModalContent as VendorModalContent,
    Text as VendorText,
} from '@chakra-ui/react';
import styled from '@emotion/styled';

export const PlayerWrapper = styled(Box)`
    aspect-ratio: 16/9;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
`;

export const CopyButton = styled(Button)`
    position: absolute;
    top: 0;
    left: 15px;
    margin-top: 20px;
    border-radius: 8px;
    background-color: transparent;
    color: #ffffff;

    &:hover {
        color: #000000;
        opacity: 0.8;
    }
`;

export const ModalContent = styled(VendorModalContent)`
    padding: 20px;
    max-width: 600px;
    display: flex;
    gap: 8px;
`;

export const Text = styled(VendorText)`
    font-size: 16px;
    line-height: 1.2;
`;

export const AnimatedText = styled(VendorText)`
    font-size: 12px;
    line-height: 1.2;
    opacity: 0;
`;

export const Span = styled.span`
    font-weight: 700;
`;

export const Code = styled.code`
    font-size: 14px;
`;
