export const buttonTheme = {
    components: {
        Button: {
            baseStyle: {
                borderRadius: 16,
            },
            defaultProps: {
                size: 'lg',
            },
        },
    },
};
