import {Player, PlayerEntityTypes} from '@vsporte/media-player';
import {useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {useBroadcast} from './queries';
import {Component} from './types';
import {calcBroadcastState, makePlayerUrl} from './utils';

import Loader from '@/components/loader';
import {RouteType} from '@/lib/types/route-type';

const BroadcastPage: Component = () => {
    const {id} = useParams<RouteType>();

    const {data: broadcast, isLoading} = useBroadcast(id);

    const broadcastState = calcBroadcastState(broadcast);

    /** Подготавливаем урл для трансляции:
     * если статус не определен (не задано время начала или конца трансляции),
     * отображаем ссылку или записи в зависимости от наличия
     * если трансляция активная отображаем ссылку на лайв, в противном случае файлы с записями
     */
    const playerUrl = useMemo(
        () =>
            makePlayerUrl(
                broadcastState,
                broadcast?.clientUrl,
                broadcast?.records?.[0],
            ),
        [broadcastState, broadcast?.clientUrl, broadcast?.records?.[0]],
    );

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Player
            isEmbed
            url={playerUrl}
            preview={broadcast?.preview}
            timeLabels={broadcast?.labelList}
            entityState={broadcastState}
            type={PlayerEntityTypes.Broadcast}
            id={id}
        />
    );
};

export default BroadcastPage;
