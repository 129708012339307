import {Undefinable} from '../types';
import {ServiceType} from '../types/app-config';

export const makeHost = (...args: Undefinable<string>[]) => {
    return args.join('/');
};

export function makeApiHost(serviceName: ServiceType) {
    const apiUrl = process.env.BASE_DATAHUB_URL || process.env.REACT_APP_BASE_DATAHUB_API_URL;

    return makeHost(apiUrl, serviceName);
}

