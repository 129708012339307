import {SCROLL_CONFIG} from '../constants/scroll';

interface ScrollTopArgs {
    parent?: boolean;
}

export function scrollToTop(options?: ScrollTopArgs) {
    if (options?.parent) {
        window.parent.scrollTo({top: 0, behavior: 'smooth'});
        return;
    }

    window.scrollTo({top: 0, behavior: 'smooth'});
}

export function disableScroll() {
    const pagePosition = window.scrollY;
    const body = document.body;

    body.classList.add('scroll-disallowed');
    body.dataset.position = pagePosition.toString();
    body.style.top = `-${pagePosition}px`;
}

export function enableScroll() {
    const body = document.body;
    const {classList} = body;

    if (document && classList.contains('scroll-disallowed')) {
        const pagePosition = Number(body.dataset.position);
        body.style.top = 'auto';
        body.classList.remove('scroll-disallowed');
        window.scroll({top: pagePosition, left: 0});
        body.removeAttribute('data-position');
    }
}

export const scrollToElement = (
    selector: string,
    scrollBlockHeight: number,
) => {
    const currentElement = document.querySelector(selector) as HTMLDivElement;

    if (currentElement?.getBoundingClientRect().y < scrollBlockHeight) {
        return;
    }

    currentElement?.scrollIntoView(SCROLL_CONFIG);
};
