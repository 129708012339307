import {Box, BoxProps, Image, ImageProps} from '@chakra-ui/react';
import styled, {StyledComponent} from '@emotion/styled';

import {COLORS} from '../../styles/colors';

export const ImageWrapper: StyledComponent<BoxProps> = styled(Box)`
    overflow: hidden;
    background-color: ${COLORS.BLACK};
    height: 100%;
    width: 100%;
`;

export const NotFoundImage: StyledComponent<ImageProps> = styled(Image)`
    object-fit: cover;
    height: 100%;
    width: 100%;
`;
