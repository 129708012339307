import {Flex} from '@chakra-ui/react';
import styled from '@emotion/styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import {css} from "@emotion/css";

export const Wrapper = styled(Flex)`
    margin: 0 auto;
`;

export const Scroll = styled(InfiniteScroll)`
    gap: 10px;
    padding: 4px;
    display: flex;
    flex-direction: column;

    @media (min-width: 512px) and (max-width: 1199px) {
        flex-direction: row;
        max-width: 500px;
        padding-bottom: 10px;
    }

    @media (min-width: 480px) and (max-width: 511px) {
        flex-direction: row;
        max-width: 466px;
        padding-bottom: 10px;
    }

    @media (min-width: 360px) and (max-width: 479px) {
        flex-direction: row;
        max-width: 348px;
        padding-bottom: 10px;
    }
`;

export const scrollClassName = css`
    height: 380px !important;

    @media (min-width: 360px) and (max-width: 1199px) {
        height: max-content !important;
    }
`;
