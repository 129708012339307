import {ThemeConfig} from 'antd';
import merge from 'lodash/merge';

import {baseTheme} from './base';
import {componentsTheme} from './components';

export const defaultTheme = merge(
    {},
    baseTheme,
    componentsTheme,
);

export const antdDefaultTheme: ThemeConfig = {
    token: {
        colorPrimary: baseTheme.colors.turquoise,
    },
};
