import {memo} from 'react';
import {useTranslation} from 'react-i18next';

import {Text} from './styles';
import {Component} from './types';

// i18next.t не работает в runtime, только с useTranslation();
// Компонент обёртка для переводов в runtime
const TranslatedLabel: Component = ({translationKey, interpolation = {}, suffix, prefix, ...textProps}) => {
    const {t} = useTranslation();

    return (
        <Text {...textProps}>
            {prefix}
            {t(translationKey, interpolation)}
            {suffix}
        </Text>
    );
};

export default memo(TranslatedLabel);
