import {MomentsQuery} from './types';

import {BroadcastMoment} from '@/lib/enums/broadcast-moment';

export const prepareFiltersData = (filter: MomentsQuery['filter']) => {
    return filter
        ? Object.entries(filter).reduce<
              {team_id: number; filter_names: BroadcastMoment[]}[]
          >((acc, [teamId, filterNames]) => {
              if (filterNames && !Number.isNaN(parseInt(teamId))) {
                  acc.push({
                      team_id: parseInt(teamId),
                      filter_names: filterNames,
                  });
              }
              return acc;
          }, [])
        : [];
};
