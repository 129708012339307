import {keyframes} from '@chakra-ui/react';

const animationKeyframes = keyframes`
    from { opacity: 1; }
    to { opacity: 0; }
`;
export const animation = `${animationKeyframes} 1s linear 1`;

export const iFrameAllow =
    'accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;web-share';
