import {CopyIcon} from '@chakra-ui/icons';
import {Modal, useDisclosure} from '@chakra-ui/react';
import {
    Player as VendorPlayer,
    PlayerEntityStates,
    PlayerEntityTypes,
} from '@vsporte/media-player';
import {motion} from 'framer-motion';
import {forwardRef, useMemo} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactPlayer from 'react-player';

import {animation} from './consts';
import {
    CopyButton,
    PlayerWrapper,
    ModalContent,
    Text,
    AnimatedText,
    Span,
    Code,
} from './styles';
import {Props} from './types';
import {calcBroadcastState, getPlayerConfig} from './utils';

import TranslatedLabel from '@/components/translated-label';

const Player = forwardRef<ReactPlayer, Props>(({broadcast, ...rest}, ref) => {
    const {isOpen, onOpen, onClose} = useDisclosure();

    const {playerUrl, codeForEmbed} = useMemo(
        () => getPlayerConfig(broadcast?.id.toString()),
        [broadcast?.id],
    );

    const broadcastState = calcBroadcastState(broadcast);

    return (
        <PlayerWrapper>
            <VendorPlayer
                isEmbed
                id={broadcast?.id}
                url={
                    broadcastState === PlayerEntityStates.Active
                        ? broadcast?.clientUrl
                        : broadcast?.records[0]
                }
                preview={broadcast?.preview}
                entityState={broadcastState}
                {...rest}
                type={PlayerEntityTypes.Broadcast}
                ref={ref}
            />
            {false && codeForEmbed && (
                <CopyToClipboard text={codeForEmbed}>
                    <CopyButton size="md" onClick={onOpen}>
                        <CopyIcon />
                    </CopyButton>
                </CopyToClipboard>
            )}
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                    <Text>
                        <Span>URL:&nbsp;</Span>
                        <Code>{playerUrl}</Code>
                    </Text>
                    <Text>
                        <Span>
                            <TranslatedLabel translationKey="ui.buttons.insertCode" />
                        </Span>
                        <Code>{codeForEmbed}</Code>
                    </Text>
                    <AnimatedText as={motion.p} animation={animation}>
                        <TranslatedLabel translationKey="ui.buttons.codeCopied" />
                    </AnimatedText>
                </ModalContent>
            </Modal>
        </PlayerWrapper>
    );
});

export default Player;
