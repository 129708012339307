import {ChakraProvider} from '@chakra-ui/react';
import React from 'react';
import {QueryClientProvider} from 'react-query';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import {routes} from './consts';
import {Component} from './types';

import BroadcastPage from '@/components/broadcast-page';
import MatchProfilePage from '@/components/match-profile-page';
import NotFound from '@/components/not-found';
import VideoPage from '@/components/video-page';
import {queryClient} from '@/lib/utils/query-client';

const App: Component = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <Router>
          <React.StrictMode>
            <Routes>
              <Route path={routes.broadcasts} element={<MatchProfilePage />} />
              <Route path={routes.broadcast} element={<BroadcastPage />} />
              <Route path={routes.video} element={<VideoPage />} />
              <Route path={routes.anyPath} element={<NotFound />} />
            </Routes>
          </React.StrictMode>
        </Router>
      </ChakraProvider>
    </QueryClientProvider>
  );
};

export default App;
