import {IBroadcastDto} from '../types/broadcast';
import apiClient from '../utils/api-client';
import {EntityAPI} from '../utils/entity-api';

class BroadcastApi implements EntityAPI {
  public find = async (id: number) =>
    apiClient.get<IBroadcastDto>(`/broadcast/${id}`);
}

export const apiInstance = new BroadcastApi();
