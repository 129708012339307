import {MomentsQuery, PlayersQuery} from './types';
import {prepareFiltersData} from './utils';

import {IListAll} from '@/lib/types/dto/list-all';
import {IMomentDto, IPlayerDto} from '@/lib/types/dto/moment';
import {EntityAPI} from '@/lib/utils/entity-api';
import {matchMomentsApi} from '@/lib/utils/http/api';
import {createQueryString} from '@/lib/utils/http/utils';

export class MomentsApi implements EntityAPI {
    public request = ({pagination, matchId, filter, playerId}: MomentsQuery) => {
        return matchMomentsApi.post<IListAll<IMomentDto>>(
            `/${createQueryString(pagination)}`,
            {
                match_id: matchId,
                filters: prepareFiltersData(filter),
                player_id: playerId,
            },
        );
    };

    public requestPlayers = ({matchId, filter, playerId, teamId}: PlayersQuery) => {
        return matchMomentsApi.post<IPlayerDto[]>(
            '/players',
            {
                match_id: matchId,
                filters: prepareFiltersData(filter),
                player_id: playerId,
                team_id: teamId,
            },
        );
    };

    public requestMomentTypes = ({matchId, playerId}: MomentsQuery) => {
        return matchMomentsApi.post<IListAll<string>>(
            '/type/players',
            {
                match_id: matchId,
                player_id: playerId,
            },
        );
    };
}

export const apiInstance = new MomentsApi();
