import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import en from './locales/en/translation.json';
import ru from './locales/ru/translation.json';

const defaultLanguage = 'ru-GB';

export const defaultNamespace = 'default';

export const resources = {
  'en-GB': {
    [defaultNamespace]: en,
  },
  'ru-GB': {
    [defaultNamespace]: ru,
  },
};

i18n.use(initReactI18next).init({
  defaultNS: defaultNamespace,
  ns: [defaultNamespace],
  resources,
  lng: defaultLanguage,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
});
