import {FC, PropsWithChildren} from 'react';

import {MatchHalf} from '@/lib/constants/match-statistics';
import {BroadcastMoment} from '@/lib/enums/broadcast-moment';
import {Undefinable} from '@/lib/types';

export type Props = PropsWithChildren<{
    matchId: number;
    broadcastId?: number;
    playerId?: number[];
    filter?: Record<string, BroadcastMoment[]>;
    handleTimeLabelPlayButtonClick: (
        timeStartSeconds: number,
        half?: MatchHalf
    ) => void;
    handleClearTimeout: () => void;
    momentsHeader?: PropsWithChildren['children'];
}>;

export type Component = FC<Props>;

type VideoQuality = 'p480' | 'p720' | 'p1080';

export const enum IVideoFormValuesKeys {
    Tags = 'tags',
    Quality = 'quality',
    SelectAll = 'selectAll',
}

export interface IVideoFormValues {
    tags?: number[];
    quality: Undefinable<VideoQuality>;
    selectAll: boolean;
}
