import {v4} from 'uuid';

import {IMoment, IMomentDto} from '@/lib/types/dto/moment';

export const mapMomentsDtoToEntity = (items?: IMomentDto[]): IMoment[] => {
    return (
        items?.map(
            ({second, moment_name, player_names, logo_url, half, id}) => ({
                second,
                logoUrl: logo_url,
                playerNames: player_names,
                momentName: moment_name,
                half,
                id,
                recordId: v4(),
            }),
        ) ?? []
    );
};
