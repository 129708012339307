import {EntityAPI} from '../utils/entity-api';

import {IListAll} from '@/lib/types/dto/list-all';
import {IMatchProcessings} from '@/lib/types/dto/match-files';
import {matchMomentsApi} from '@/lib/utils/http/api';
import {createQueryString} from '@/lib/utils/http/utils';

class MatchProcessingApi implements EntityAPI {
  public request = (matchId: string) => {
    return matchMomentsApi.get<IListAll<IMatchProcessings>>(
        `/status${createQueryString({match_id: matchId})}`,
    );
  };
}

export const apiInstance = new MatchProcessingApi();
