export const PLACEHOLDER = 'tags.selectPlayers';

export const MAX_SELECT_TAGS_COUNT = 2;

export const PLAYER_POSITIONS: {[language: string]: string[]} = {
    en: [
        'GK',
        'LB',
        'LCD',
        'CD',
        'RCD',
        'RB',
        'LWB',
        'LDM',
        'DM',
        'RDM',
        'RWB',
        'LM',
        'LCM',
        'CM',
        'RCM',
        'RM',
        'LW',
        'LAM',
        'AM',
        'RAM',
        'RW',
        'LWF',
        'WF',
        'RWF',
        'LCF',
        'CF',
        'RCF',
    ],
    ru: [
        'ВРТ',
        'ЛЗ',
        'ЛЦЗ',
        'ЦЗ',
        'ПЦЗ',
        'ПЗ',
        'ЛФЗ',
        'ЛОП',
        'ЦОП',
        'ПОП',
        'ПФЗ',
        'ЛП',
        'ЛЦП',
        'ЦП',
        'ПЦП',
        'ПП',
        'ЛВ',
        'ЛАП',
        'ЦАП',
        'ПАП',
        'ПВ',
        'ЛФА',
        'ФРВ',
        'ПФА',
        'ЛФД',
        'ЦФД',
        'ПФД',
    ],
};
