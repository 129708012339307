import moment from 'moment';

import {
    FORMAT_DDDMMYY_HHMMSS,
    HOUR_LENGTH,
    MINUTE_LENGTH,
    TIME_FORMAT,
} from '../constants/date-time';

export const formatFromISODate = (date?: string | Date): string =>
    date ? moment(date).format(FORMAT_DDDMMYY_HHMMSS) : '-';

export const getSecondsFromString = (time: string) =>
    moment(time, TIME_FORMAT).diff(moment().startOf('day'), 'seconds');

export const getTimeFromSeconds = (value: number, format = TIME_FORMAT) => {
    const hours = Math.trunc(value / HOUR_LENGTH);
    const minutes = Math.trunc((value - hours * HOUR_LENGTH) / MINUTE_LENGTH);
    const seconds = value - hours * HOUR_LENGTH - minutes * MINUTE_LENGTH;

    return moment(`${hours}:${minutes}:${seconds}`, TIME_FORMAT).format(format);
};

export const getMMSSTimeFromSeconds = (time: number) => {
    const [hours, minutes, seconds] = getTimeFromSeconds(time).split(':');

    const preparedMinutes = (
        !Number.isNaN(parseInt(hours)) && !Number.isNaN(parseInt(minutes))
            ? parseInt(hours) * MINUTE_LENGTH + parseInt(minutes)
            : ''
    ).toString();

    return `${`${
        preparedMinutes.length === 1 ? '0' : ''
    }${preparedMinutes}`}:${seconds}`;
};
