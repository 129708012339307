export const PLACEHOLDER_TRANSLATION_KEY = 'tags.selectCompilations';

export const MAX_SELECT_TAGS_COUNT = 2;

export const TEAM_MOMENTS = [
    'goals',
    'goals_auto',
    'goalmoments',
    'shots',
    'shots_on_target',
    'dribbles',
    'goalkeeper_kicks',
    'crosses_success',
    'freekicks',
    'corners',
    'outs',
    'mistakes',
    'cards_yellow',
    'cards_red',
    'fouls',
    'passes_to_penalty_zone',
    'fouls_with_penalty',
];
