import {FC, PropsWithChildren} from 'react';

import {IMoment} from '@/lib/types/dto/moment';

export type Props = PropsWithChildren<{
    moment: IMoment;
    handleTimeLabelPlayButtonClick: (moment: IMoment) => void;
    isPlaying?: boolean;
    setPlaying?: (value: boolean) => void;
}>;

export type Component = FC<Props>;

export enum BroadcastMoment {
    Goals = 'goals',
    GoalsAuto = 'goals_auto',
    Goalmoments = 'goalmoments',
    ShotsOnTarget = 'shots_on_target',
    CrossesSuccess = 'crosses_success',
    Freekicks = 'freekicks',
    Corners = 'corners',
    Outs = 'outs',
    Mistakes = 'mistakes',
    CardsYellow = 'cards_yellow',
    CardsRed = 'cards_red',
    Fouls = 'fouls',
    PassesToPenaltyZone = 'passes_to_penalty_zone',
    CarryingToPenaltyZone = 'carrying_to_penalty_zone',
    FoulsWithPenalty = 'fouls_with_penalty',
    Counterattacks = 'counterattacks',
    AttacksPositionalWithShot = 'attacks_positional_with_shot',
    FirstHalfStart = 'first_half_start',
    SecondHalfStart = 'second_half_start',
    GoalkeeperKicks = 'goalkeeper_kicks',
    Dribbles = 'dribbles',
    Shots = 'shots',
}
