import {Box, Spinner} from '@chakra-ui/react';
import styled from '@emotion/styled';

export const LoaderWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderSpinner = styled(Spinner)`
  width: 30px;
  height: 30px;
`;
