export const drawerTheme = {
    components: {
        Drawer: {
            variants: {
                nonModal: {
                    dialog: {
                        pointerEvents: 'auto',
                    },
                    dialogContainer: {
                        pointerEvents: 'none',
                    },
                },
            },
        },
    },
};
