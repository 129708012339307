import {PlayerEntityStates} from '@vsporte/media-player';
import moment from 'moment';

import {IBroadcast} from '@/lib/types/broadcast';

/** Утилита для расчета состояния трансляции
 * notFound - не найдена
 * notStarted - не начата
 * finished - завершена
 * active - активная трансляция
 */
export const calcBroadcastState = (
    broadcast?: IBroadcast,
): PlayerEntityStates => {
    if (!broadcast) {
        return PlayerEntityStates.NotFound;
    }

    if (!broadcast.startTime || !broadcast.endTime) {
        return PlayerEntityStates.NotStated;
    }

    if (moment().isAfter(broadcast.endTime)) {
        return PlayerEntityStates.Finished;
    }

    if (moment().isBefore(broadcast.startTime)) {
        return PlayerEntityStates.NotStarted;
    }

    return PlayerEntityStates.Active;
};

export const makePlayerUrl = (
    state: PlayerEntityStates,
    clientUrl?: string,
    record?: string,
) => {
    if (state === PlayerEntityStates.NotStated) {
        return clientUrl ?? record;
    }

    return state === PlayerEntityStates.Active ? clientUrl : record;
};
