import {useQuery} from 'react-query';

import {matchQueryKey} from '../constants/query-keys';

import {apiInstance as matchApi} from '@/lib/api/match';
import {IMatchBroadcastDto, IMatchFitnessBorderDto, IMatchTournament} from '@/lib/types/dto/match';
import {IMatchBroadcast, IMatchFitnessBorder} from '@/lib/types/dto/tournament';
import {IMatch} from '@/lib/types/entity/match';

export const mapBroadcastsDtoToEntity = (
    data: IMatchBroadcastDto[] = [],
): IMatchBroadcast[] =>
    data.map(
        ({
           broadcast_id,
           start_first_extra_half,
           start_first_half,
           start_second_extra_half,
           start_second_half,
           penalty_shootout,
         }) => ({
          broadcastId: broadcast_id,
          startFirstExtraHalf: start_first_extra_half,
          startFirstHalf: start_first_half,
          startSecondHalf: start_second_half,
          startSecondExtraHalf: start_second_extra_half,
          penaltyShootout: penalty_shootout,
        }),
    );

export const mapFitnessBordersDtoToEntity = (
    data: IMatchFitnessBorderDto[] = [],
): IMatchFitnessBorder[] =>
    data.map(
        ({
           match_id,
           match_half,
           match_end_half,
         }) => ({
          matchId: match_id,
          matchHalf: match_half,
          matchEndHalf: match_end_half,
        }),
    );

const mapBroadcastDtoToEntity = (dto: IMatchTournament): IMatch => {
  return {
    matchDate: dto.match_date,
    matchToken: dto.match_token,
    pdfReports: dto.pdf_reports,
    guestName: dto.guest_name,
    guestLogo: dto.guest_logo,
    guestTeamId: dto.guest_team_id,
    gameScore: dto.game_score,
    id: dto.id,
    hostName: dto.host_name,
    hostShortname: dto.host_shortname,
    hostTeamId: dto.host_team_id,
    guestShortname: dto.guest_shortname,
    hostLogo: dto.host_logo,
    videoUrl: dto.video_url,
    startFirstHalf: dto.start_first_half,
    startFirstExtraHalf: dto.start_first_extra_half,
    startSecondExtraHalf: dto.start_second_extra_half,
    startSecondHalf: dto.start_second_half,
    penaltyShootout: dto.penalty_shootout,
    matchFiles: dto.match_files,
    matchProcessings: dto.match_processings,
    broadcasts: mapBroadcastsDtoToEntity(dto.broadcasts),
    tournamentId: dto.tournament_id,
    tourNum: dto.tour_num,
    seasonId: dto.season_id,
    matchFitnessBorders:
        dto?.match_fitness_borders ?
            mapFitnessBordersDtoToEntity(dto.match_fitness_borders) :
            [],
    isFitnessDataAvailable: dto.is_fitness_data_available,
    isFitnessRealtime: dto.is_fitness_realtime,
  };
};

export function useMatch(matchId?: number) {
  return useQuery(matchQueryKey, async () => {
    if (!matchId) {
      return;
    }
    const response = await matchApi.find(matchId);

    if (response.data) {
      return mapBroadcastDtoToEntity(response.data);
    }
  });
}
