import {IPlayerDto, IPlayer} from '@/lib/types/dto/moment';

export const mapPlayersDtoToEntity = (items?: IPlayerDto[]): IPlayer[] => {
    return (
        items?.map(
            ({player_id, id, name, logo_url, position_ru, position_en}) => ({
                playerId: player_id,
                logoUrl: logo_url,
                name,
                id,
                positionRu: position_ru,
                positionEn: position_en,
            }),
        ) ?? []
    );
};
