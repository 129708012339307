import {PlayerEntityStates} from '@vsporte/media-player';
import dayjs from 'dayjs';

import {iFrameAllow} from './consts';

import {IBroadcast} from '@/lib/types/dto/broadcast';

export const getPlayerConfig = (id?: string) => {
    if (!id) {
        return {};
    }
    const playerUrl = `${process.env.NEXT_PUBLIC_EMBED_URL}/embed/broadcast/${id}`;
    const codeForEmbed = `
        <iframe
            width="100%"
            height="100%"
            src="${playerUrl}"
            allowFullScreen
            allow="${iFrameAllow}"
        />
    `;

    return {playerUrl, codeForEmbed};
};

/** Утилита для расчета состояния трансляции
 * notFound - не найдена
 * notStarted - не начата
 * finished - завершена
 * active - активная трансляция
 */
export const calcBroadcastState = (
    broadcast?: IBroadcast,
): PlayerEntityStates => {
    if (!broadcast) {
        return PlayerEntityStates.NotFound;
    }

    if (!broadcast.startTime || !broadcast.endTime) {
        return PlayerEntityStates.NotStated;
    }

    if (dayjs().isAfter(broadcast.endTime)) {
        return PlayerEntityStates.Finished;
    }

    if (dayjs().isBefore(broadcast.startTime)) {
        return PlayerEntityStates.NotStarted;
    }

    return PlayerEntityStates.Active;
};
