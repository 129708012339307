import {forwardRef, memo, useMemo} from 'react';
import {useParams} from 'react-router-dom';

import Moments from '../moments';

import {EVENTS_IFRAME_URL} from './consts';
import {Wrapper, FrameWrapper} from './styles';
import {Component} from './types';
import {getIframeToken} from './utils';

import {useMatch} from '@/lib/queries/use-match';
import {RouteType} from '@/lib/types/route-type';

const MomentsBlock: Component = forwardRef(
    (
        {
            broadcast,
            filters,
            playersIds,
            handleTimeLabelClick,
            handleClearTimeout,
            isMatchProcessingSuccess,
        },
        ref,
    ) => {
        const {id} = useParams<RouteType>();
        const {data: matchData} = useMatch(Number(id));

        const iframeToken = useMemo(() => {
            return getIframeToken(matchData?.videoUrl);
        }, [matchData?.videoUrl]);

        const moments = useMemo(() => {
            if (isMatchProcessingSuccess) {
                return (
                    <Moments
                        matchId={Number(id)}
                        handleTimeLabelPlayButtonClick={handleTimeLabelClick}
                        filter={filters}
                        playerId={playersIds}
                        ref={ref}
                        handleClearTimeout={handleClearTimeout}
                        broadcastId={broadcast?.id}
                    />
                );
            }
            return (
                <iframe
                    height="100%"
                    width="100%"
                    src={`${EVENTS_IFRAME_URL}${iframeToken}`}
                />
            );
        }, [
            isMatchProcessingSuccess,
            iframeToken,
            id,
            handleTimeLabelClick,
            filters,
            playersIds,
            ref,
            handleClearTimeout,
            broadcast?.id,
        ]);

        return (
            <Wrapper>
                <FrameWrapper>{moments}</FrameWrapper>
            </Wrapper>
        );
    },
);

export default memo(MomentsBlock);
