import {COLORS} from '../../styles/colors';

import {BadgeText, Indicator, LiveBadgeWrapper} from './styles';
import {Component} from './types';

export const LiveBadge: Component = ({isLive, ...props}) => {
    return (
        <LiveBadgeWrapper
            borderRadius="md"
            background="blackAlpha.700"
            {...props}
        >
            <Indicator style={{backgroundColor: `${isLive ? COLORS.RED : 'gray'}`}} />
            <BadgeText fontSize="sm">Live</BadgeText>
        </LiveBadgeWrapper>
    );
};
