import {useMemo} from 'react';
import {UseInfiniteQueryResult} from 'react-query';

import {IListAll} from '../types/dto/list-all';

import {LANGUAGE_STORAGE_KEY} from '@/components/providers/i18next/consts';

export function useInfiniteQueryHook<TParams, TRecord>(
    hook: (
        params: TParams
    ) => UseInfiniteQueryResult<IListAll<TRecord>, unknown>,
    params: TParams,
) {
    const language = localStorage.getItem(LANGUAGE_STORAGE_KEY);
    const {data, ...rest} = hook({...params, language});

    const items = useMemo(() => {
        return data?.pages.flatMap((page) => page.items) ?? [];
    }, [data?.pages]);

    return {items, ...rest};
}
