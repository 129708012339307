import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import {useCallback} from 'react';
import {useInfiniteQuery, useQuery} from 'react-query';

import {apiInstance} from '../api/moments-api';
import {momentsQueryKey, playerMomentsQueryKey} from '../constants/query-keys';
import {mapMomentsDtoToEntity} from '../utils/mappers/map-moments';

import {BroadcastMoment} from '@/lib/enums/broadcast-moment';

const maxPageSize = 1000;

export type UseInfinityMomentsParams = {
    matchId: number;
    playerId?: number[];
    filter?: Record<string, BroadcastMoment[]>;
};

export const useInfiniteMoments = ({
    matchId,
    filter,
    playerId,
}: UseInfinityMomentsParams) => {
    const fetchMoments = useCallback(
        async ({pageParam = 1}) => {
            /** Убираем фильтр, если это пустой массив */
            const response = await apiInstance.request({
                pagination: {page: pageParam, size: maxPageSize},
                filter: omitBy(filter, (value) => isEmpty(value)),
                matchId,
                playerId,
            });

            return {
                ...response.data,
                items: mapMomentsDtoToEntity(response?.data?.items),
            };
        },
        [matchId, filter, playerId],
    );

    return useInfiniteQuery(
        [momentsQueryKey, matchId, filter, playerId],
        fetchMoments,
        {
            getNextPageParam: ({page, pages}) => {
                return page < pages ? page + 1 : undefined;
            },
        },
    );
};

export const useMomentsType = ({
    matchId,
    playerId,
}: UseInfinityMomentsParams) => {
    return useQuery(
        [playerMomentsQueryKey, matchId, playerId],
        async () => {
            const {data} = await apiInstance.requestMomentTypes(
                {
                    matchId,
                    playerId,
                },
            );
            return data.items;
        },
    );
};
