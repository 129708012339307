import {extendTheme} from '@chakra-ui/react';

import {
    defaultTheme,
    antdDefaultTheme,
} from './default';

const appTheme = extendTheme(defaultTheme);
export const antdTheme = antdDefaultTheme;

export default appTheme;
