import merge from 'lodash/merge';

import {buttonTheme} from './button';
import {drawerTheme} from './drawer';

export const componentsTheme = merge(
    {},
    buttonTheme,
    drawerTheme,
);
