import {Player, PlayerEntityTypes, PlayerEntityStates} from '@vsporte/media-player';
import {useParams} from 'react-router-dom';

import {useVideo} from './queries';
import {Component} from './types';

import Loader from '@/components/loader';  
import {RouteType} from '@/lib/types/route-type';

const VideoPage: Component = () => {
  const {id} = useParams<RouteType>();

  const {data: video, isLoading} = useVideo(id);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Player
      id={id}
      url={video?.url}
      preview={video?.preview}
      timeLabels={video?.labelList}
      entityState={
        video ? PlayerEntityStates.Active : PlayerEntityStates.NotFound
      }
      type={PlayerEntityTypes.Video}
      isEmbed
    />
  );
};

export default VideoPage;
