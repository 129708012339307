export enum PlayerEntityTypes {
    Video = 'video',
    Broadcast = 'broadcast',
}

/** Состояние видео или трансляции
 * NotFound - не найдено
 * Active - активная запись
 * NotStarted - только для трансляций, трансляция еще не началась
 * Finished - только для трансляций, трансляция завершилась
 */
export enum PlayerEntityStates {
    NotFound = 'notFound',
    NotStarted = 'notStarted',
    Active = 'active',
    Finished = 'finished',
    NotStated = 'notStated',
}
