import {useQuery} from 'react-query';

import {matchProcessingQueryKey} from '../constants/query-keys';

import {apiInstance as matchProcessingStatusApi} from '@/lib/api/match-processing-status';

export const useMatchProcessingStatus = (matchId?: string) => {
    return useQuery([matchProcessingQueryKey, matchId], async () => {
        if (!matchId) {
            return;
        }
        const response = await matchProcessingStatusApi.request(matchId);
        return response.data.items?.[0].status;
    });
};
