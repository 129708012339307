import {useQuery} from 'react-query';

import {playerMomentsQueryKey} from '../constants/query-keys';

import {apiInstance as momentsApi} from '@/lib/api/moments-api';
import {BroadcastMoment} from '@/lib/enums/broadcast-moment';
import {mapPlayersDtoToEntity} from '@/lib/utils/mappers/map-players';

export type UsePlayersParams = {
    matchId: number;
    playerId?: number[];
    teamId: number;
    filter?: Record<string, BroadcastMoment[]>;
};

export const usePlayers = ({matchId, filter, playerId, teamId}: UsePlayersParams) => {
    return useQuery(
        [playerMomentsQueryKey, teamId, playerId, filter],
        async () => {
            const {data} = await momentsApi.requestPlayers(
                {matchId, filter, playerId, teamId},
            );
            return data ? mapPlayersDtoToEntity(data) : undefined;
        },
    );
};
