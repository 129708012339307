import {
    Box,
    BoxProps,
    Circle,
    SquareProps,
    Text,
    TextProps,
} from '@chakra-ui/react';
import styled, {StyledComponent} from '@emotion/styled';

import {COLORS} from '../../styles/colors';

export const LiveBadgeWrapper: StyledComponent<BoxProps> = styled(Box)`
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0px 3px;
    position: absolute;
    top: 16px;
    right: 16px;

    &:hover {
        cursor: pointer;
    }
`;

export const Indicator: StyledComponent<SquareProps> = styled(Circle)`
    min-width: 12px;
    min-height: 12px;
`;

export const BadgeText: StyledComponent<TextProps> = styled(Text)`
    text-transform: uppercase;
    color: ${COLORS.WHITE};
`;
