import {memo} from 'react';

import {ImageWrapper, NotFoundImage} from './styles';
import {Component} from './types';

import videoNotFound from '@/assets/video-not-found.png';

const NotFound: Component = () => {
  return (
    <ImageWrapper>
      <NotFoundImage src={videoNotFound} />
    </ImageWrapper>
  );
};

export default memo(NotFound);
